import tippy, { roundArrow } from 'tippy.js'
import 'tippy.js/dist/svg-arrow.css'
import 'tippy.js/themes/light.css'

function triggerTooltip (element) {
  const content = element.parentNode.querySelector('.ui-tooltip')
  if (content === null) {
    return
  }
  content.style.display = 'block'
  content.classList.add('qtip-content')

  const wrapper = document.createElement('div')
  wrapper.classList.add('qtip-bootstrap')
  wrapper.setAttribute('aria-atomic', 'true')

  wrapper.appendChild(createTitleBar(content.dataset.title))
  wrapper.appendChild(content)

  tippy(element, {
    arrow: roundArrow,
    content: wrapper,
    placement: 'right-start',
    onShow: onShow(element),
    onHidden: onHidden(element)
  })
}

function triggerPopover (element) {
  const content = element.parentNode.querySelector('.ui-popover')
  if (content === null) {
    return
  }
  content.style.display = 'block'
  content.classList.add('qtip-content')

  const wrapper = document.createElement('div')
  wrapper.classList.add('qtip-bootstrap', 'light')
  wrapper.setAttribute('aria-atomic', 'true')
  wrapper.appendChild(content)

  tippy(element, {
    arrow: roundArrow,
    content: wrapper,
    placement: 'bottom',
    trigger: 'click',
    interactive: true,
    theme: 'light',
    appendTo: document.body,
    animation: 'fade',
    onShow: onShow(element),
    onHidden: onHidden(element)
  })
}

function onShow (element) {
  return function () {
    element.classList.add('ui-tooltip-active')
  }
}

function onHidden (element) {
  return function () {
    element.classList.remove('ui-tooltip-active')
  }
}

export function uiInitTooltips () {
  document.querySelectorAll('.ui-tooltip-trigger')
    .forEach(triggerTooltip)
  document.querySelectorAll('.ui-popover-trigger')
    .forEach(triggerPopover)
  document.querySelectorAll('.ui-disabled, input[disabled], select[disabled], textarea[disabled]')
    .forEach(triggerDisabled)
}

export function createTitleBar (titleText) {
  const title = document.createElement('div')
  title.classList.add('qtip-title')
  title.setAttribute('aria-atomic', 'true')
  title.innerText = titleText

  const titleBar = document.createElement('div')
  titleBar.classList.add('qtip-titlebar')
  titleBar.appendChild(title)

  return titleBar
}

// Disabled elements are non-interactive and don't trigger the most events, so
// we have to calculate the position and trigger tippy manually.
function triggerDisabled (element) {
  let text = 'Diese Funktion kann derzeit nicht genutzt werden bzw. dieses Feld kann nicht bearbeitet werden.'

  if (element.dataset.disabledTooltip) {
    text = element.dataset.disabledTooltip
  }

  const content = document.createElement('div')
  content.classList.add('qtip-content')
  content.innerText = text

  const wrapper = document.createElement('div')
  wrapper.classList.add('qtip-bootstrap')
  wrapper.setAttribute('aria-atomic', 'true')

  wrapper.appendChild(createTitleBar('Deaktiviert'))
  wrapper.appendChild(content)

  const instance = tippy(element.parentNode, {
    arrow: roundArrow,
    content: wrapper,
    placement: 'auto-start',
    trigger: 'manual',
    getReferenceClientRect: () => element.getBoundingClientRect(),
    onShow: onShow(element),
    onHidden: onHidden(element)
  })

  element.addEventListener('mouseout', () => instance.hide())
  element.addEventListener('mouseenter', () => instance.show())
}
