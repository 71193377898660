import * as R from 'ramda'

export function expandDetails (e) {
  const swap = e.target.dataset.expandText
  const detailElements = e.target.closest('.expandable')
    .getElementsByClassName('more')

  e.target.dataset.expandText = e.target.text
  e.target.text = swap
  R.forEach(el => el.classList.toggle('visible'), detailElements)

  e.preventDefault()
}
