import $ from 'jquery'

function loadContent (e) {
  e.preventDefault()
  const href = $(this).attr('href')
  const $target = $($(this).data('target'))
  let $container = $target
  if ($target.length === 0 || $target.is('.js-fetch-content--loading')) {
    return
  }

  if ($target.find('.js-fetch-content--container').length > 0) {
    $container = $target.find('.js-fetch-content--container')
  }

  $target.removeClass('d-none').addClass('js-fetch-content--loading')
  $container.html('').load(href, (res, status) => {
    $target.removeClass('js-fetch-content--loading')
    if (status === 'error') {
      $container.html('Fehler beim Laden')
    }
  })
}

export default function bindFechContent () {
  $(document).on('click', 'a.js-fetch-content[href][data-target]', loadContent)
}
