import $ from 'jquery'
import {
  fenster,
  setVertragspartner,
  closeWindow,
  closeWindowAndUpdateOpener,
  projectInit,
  updateOpener
} from '@/js/project'
import DocumentUploader, { createBuildingMetadata } from '@/components/Document/DocumentUploader'
import { createVue, registerComponents } from '@/js/vue'
import { expandDetails } from '@/js/appearence.js'
import * as Sentry from '@sentry/browser'
import { uiInitTooltips } from '@/js/tooltip'
import { initTopicApp } from '@/js/topic-app'
import { createApp } from 'vue'
import FomanticUI from 'vue-fomantic-ui'
import { RTEAutoconfig } from '@/js/rte.js'
import { configureFormBlock } from '@/js/formblock.js'
import bindFechContent from '@/js/fetch-content.js'

export function uiInit () {
  $('.addon').hide()
  $('.select2').select2()

  $('textarea').each(function () {
    autoresize(this)
  }).on('input', function () {
    autoresize(this)
  })

  $.fn.datepicker.defaults.language = 'de'
  $.fn.datepicker.defaults.format = 'dd.mm.yyyy'
  $.fn.datepicker.defaults.weekStart = 1

  initDocumentUploader()

  for (const el of document.getElementsByClassName('expand-details')) {
    el.addEventListener('click', expandDetails)
  }
  projectInit()
  uiInitTooltips()
  if (document.body.dataset.sentry) {
    const sentryOptions = JSON.parse(document.body.dataset.sentry)

    const sentryIntegrations = []

    if ('tracesSampleRate' in sentryOptions && sentryOptions.tracesSampleRate > 0) {
      sentryIntegrations.push(Sentry.browserTracingIntegration({
        enableInp: true
      }))
    }

    if ('profilesSampleRate' in sentryOptions && sentryOptions.profilesSampleRate > 0) {
      sentryIntegrations.push(Sentry.browserProfilingIntegration())
    }

    if (('replaysSessionSampleRate' in sentryOptions && sentryOptions.replaysSessionSampleRate > 0) ||
        ('replaysOnErrorSampleRate' in sentryOptions && sentryOptions.replaysOnErrorSampleRate > 0)) {
      sentryIntegrations.push(Sentry.replayIntegration())
    }

    if (sentryIntegrations.length > 0) {
      sentryOptions.integrations = sentryIntegrations
    }

    Sentry.init(sentryOptions)
    Sentry.setUser({ username: document.body.dataset.user })
  }

  const rte = new RTEAutoconfig()
  rte.find()

  configureFormBlock()
  bindFechContent()

  globalThis.topicApp = initTopicApp()

  registerComponents()
}

export function initializeWindow (window) {
  window.$ = window.jQuery = $
  window.Vue = createApp({})

  window.Vue.use(FomanticUI)

  window.fenster = fenster
  window.closeWindow = closeWindow
  window.updateOpener = updateOpener
  window.closeWindowAndUpdateOpener = closeWindowAndUpdateOpener
  window.setVertragspartner = setVertragspartner
}

function autoresize (textarea) {
  textarea.style.height = '0px' // Reset height, so that it not only grows but also shrinks
  textarea.style.height = (textarea.scrollHeight + 10) + 'px' // Set new height
}

function initDocumentUploader () {
  createVue(DocumentUploader, 'document-uploader', {}, {
    server: 'server',
    readonly: 'readonly',
    canDelete: 'canDelete',
    deleteRoute: 'deleteRoute',
    routeParams: 'routeParams'
  })
  createVue(DocumentUploader, 'building-uploader', {
    metadata: createBuildingMetadata()
  }, {
    server: 'server',
    readonly: 'readonly',
    canDelete: 'canDelete',
    deleteRoute: 'deleteRoute',
    routeParams: 'routeParams'
  })
}
