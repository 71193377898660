import $ from 'jquery'

export class FormBlock {
  form
  button = null
  submitted = false
  constructor (form) {
    this.form = form
  }

  submit (e) {
    if (this.form.checkValidity()) {
      if (this.submitted) {
        e.preventDefault()
      } else {
        this.submitted = true
        this.block()
      }
    }
  }

  setButton (button) {
    if (!this.submitted) {
      this.button = button
    }
  }

  getButton () {
    if (this.button !== null) {
      return this.button
    }

    return $(this.form).find('input[type=submit], button[type=submit]').get(0) ?? null
  }

  block () {
    $(this.form).find('input, textarea').prop('readonly', true)
    const button = this.getButton()
    if (button === null) {
      return
    }

    const $button = $(button)
    if ($button.is('button.aButton')) {
      $button.addClass('iconized')

      let $icon = $button.find('.icon')

      if ($icon.length === 0) {
        $('<span class="icon"></span>').prependTo($button)
        $icon = $button.find('.icon')
      }

      $icon.attr('class', 'icon loading')
    }
  }

  static getInstance (form) {
    const $form = $(form)
    let formblock = $form.data('formblock')
    if (!formblock) {
      formblock = new FormBlock(form)
    }
    $form.data('formblock', formblock)
    return formblock
  }
}

export function configureFormBlock () {
  function setButton () {
    FormBlock.getInstance($(this).parents('form').get(0)).setButton(this)
  }
  function submit (e) {
    FormBlock.getInstance(this).submit(e)
  }

  const selector = 'form.js-block'
  $(document).on(
    'click',
    `${selector} input[type=submit], ${selector} button[type=submit]`,
    setButton
  )
  $(document).on('submit', selector, submit)
}
