import $ from 'jquery'
import tinymce from 'tinymce'

export function lib () {
  if (tinymce.baseURL.match(/\/$/)) {
    tinymce.baseURL = tinymce.baseURL.substring(0, tinymce.baseURL.length - 1)
  }
  if (!tinymce.baseURL.match(/\/tinymce$/)) {
    tinymce.baseURL = tinymce.baseURL + '/tinymce'
  }

  return tinymce
}

export class RTEBuilder {
  options = {
    plugins: []
  }

  toolbar = [
    'styles',
    'bold',
    'underline',
    'italic',
    'strikethrough',
    'hr',
    'outdent',
    'indent'
  ]

  constructor (provider) {
    if (typeof provider === 'undefined') {
      provider = lib
    }

    this.provider = provider
  }

  withInput (element) {
    this.options.target = element
    return this
  }

  withDefault () {
    this.withMinimal()
    this.withImages()
    this.withHeight(400)
    return this
  }

  withMinimal () {
    this.options.menubar = false
    this.options.statusbar = false
    return this
  }

  withImages () {
    this.options.plugins.push('image')
    this.options.images_file_types = 'jpg,png,gif,webp'
    this.options.file_picker_types = 'file'
    return this
  }

  withHeight (height) {
    this.options.height = height
    return this
  }

  build () {
    const pr = this.provider()

    this.options.toolbar = this.toolbar.join(' ')

    this.options.setup = (editor) => {
      editor.on('blur', function () {
        tinymce.triggerSave()
      })
    }
    pr.init(this.options)
  }
}

export class RTEAutoconfig {
  find () {
    $('textarea.js-rte').each(function () {
      (new RTEBuilder())
        .withInput($(this).get(0))
        .withDefault()
        .build()
    })
  }
}
