import TopicForm from '@/components/History/DynamicTopicForm'
import { createVue } from '@/js/vue'

export function initTopicApp () {
  return createVue(TopicForm, 'topic-app', {}, {
    userId: 'userId',
    prefix: 'prefix',
    clearable: 'isClearable',
    initialTopicId: 'topicId',
    initialSubtopicId: 'subtopicId'
  })
}
